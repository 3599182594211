import React, {Fragment, useState} from 'react'
import {Route, Switch, Redirect, useRouteMatch} from 'react-router-dom'
import {isEmpty} from 'lodash'
import {capitalize} from 'admin/lib/format'
import useAccount from 'admin/hooks/useAccount'
import useDocument from 'admin/hooks/useDocument'
import Statement from 'admin/components/Statement'
import Breadcrumbs from 'admin/components/ui/Breadcrumbs'
import useBoolState from 'admin/hooks/useBoolState'
import History from 'admin/components/History'
import Modal from 'admin/components/ui/Modal'
import Flash from 'admin/components/Flash'
import Edit from './Edit'
import Show from './Show'
import NewService from 'admin/components/NewService'
import Service from 'admin/components/Service'

export default () => {
  const match = useRouteMatch()
  const {organisationID, accountID} = match.params
  const [editing, setEditing] = useState(false)
  const {account, errors, update, recalculate} = useAccount(organisationID, accountID)
  const [showActivityModal, setShowActivityModal, toggleShowActivityModal] = useBoolState()

  useDocument({title: account.name})

  const handleEdit = () => setEditing(true)
  const handleCancel = () => setEditing(false)

  const handleSave = changes => {
    update(changes, () => setEditing(false))
  }

  if (isEmpty(account)) return null

  const breadcrumbs = (
    <Breadcrumbs
      breadcrumbs={[
        {
          path: `/orgs/${organisationID}/accounts/${account.state}`,
          label: `Manage ${account.state} accounts`
        }
      ]}>
      <h3 className='title'>{account.displayName}</h3>
    </Breadcrumbs>
  )

  return (
    <Fragment>
      <Modal onClose={toggleShowActivityModal} isOpen={showActivityModal}>
        <Flash />
        <History itemType='Account' itemId={accountID} onAddNote={notes => update({notes})} />
      </Modal>

      <div id='admin_account'>
        {showActivityModal ? null : <Flash />}

        {account.state !== 'active' ? (
          <section
            className={`section py-3 ${
              account.state === 'draft' ? 'has-background-info' : 'has-background-grey-dark'
            }`}>
            <div className='container has-text-weight-semibold has-text-white-bis is-flex is-align-items-center'>
              <span style={{marginRight: 'auto'}}>{capitalize(account.state)}</span>
              {account.state === 'draft' ? (
                <button onClick={() => update({state: 'active'})} className='button is-small is-dark'>
                  Activate
                </button>
              ) : account.state === 'archived' ? (
                <button onClick={() => update({state: 'active'})} className='button is-small is-dark'>
                  Unarchive
                </button>
              ) : null}
            </div>
          </section>
        ) : null}

        <section className='section overview'>
          <div className='container'>
            {editing ? (
              <Edit
                breadcrumbs={breadcrumbs}
                account={account}
                errors={errors}
                onCancel={handleCancel}
                onSave={handleSave}
              />
            ) : (
              <Show
                breadcrumbs={breadcrumbs}
                account={account}
                onEdit={handleEdit}
                onArchive={() => update({state: 'archived'})}
                onSuspend={() => update({isSuspended: true})}
                onUnsuspend={() => update({isSuspended: false})}
                onShowActivity={toggleShowActivityModal}
                onRecalculate={() => recalculate({accountIds: [accountID]})}
              />
            )}
          </div>
        </section>
      </div>

      <Switch>
        <Route path={`${match.path}/transactions`} component={Statement} />
        <Route exact path={`${match.path}/services/:serviceID(\\d+)`} component={Service} />
        <Route exact path={`${match.path}/services/new`} component={NewService} />
        <Redirect exact path={match.path} to={`${match.path}/transactions`} />
      </Switch>
    </Fragment>
  )
}
